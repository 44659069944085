import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../Layout';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import {
    Paper,
    ButtonBase,
    Tooltip,
    IconButton,
    TextField,
    Divider
} from '@mui/material';
import { defaultImage } from '@cloudinary/url-gen/actions/delivery';
import DataService from '../../../services/dataService';
import { siteMetadata } from '../../../../gatsby-config';
import { WindowRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setUpdatingData } from '../../../redux/admin/adminSlice';

const ChoosePost = ({ data, pageContext }) => {

    const dispatch = useDispatch();

    const [displayOverlay, setDisplayOverlay] = React.useState(false);
    const [displayEditOverlay, setDisplayEditOverlay] = React.useState(false);

    const [newPostTitle, setNewPostTitle] = React.useState('');
    const [newPostType, setNewPostType] = React.useState('');
    const [newSingularPostType, setNewSingularPostType] = React.useState('');

    const [editablePostId, setEditablePostId] = React.useState('');
    const [editablePostType, setEditablePostType] = React.useState('');

    React.useEffect(() => {
        console.log(data);
    }, []);

    const createNewPostType = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        DataService.create({
            item_type: { type: 'item_type', id: siteMetadata.datoCmsModelIds.Post.toString() },
            title: newPostTitle,
            slug: newPostTitle.split(' ').join('-'),
            posttype: newPostType,
            post_type_singular: newSingularPostType,
            site: data.datoCmsWebsite.id.split('-')[1],
            published: new Date().toISOString()
        }).then( res => {
            window.location.reload()
            dispatch(setUpdatingData({ updatingData: false }));
        });
    }


    return (
        <Layout>
            <div>

                {
                    data.allDatoCmsPost.distinct.length ? (
                        <p className="text-center text-2xl font-bold" style={{ color: "#45425a" }}>Post Categories</p>
                    )
                    :
                    (
                        <p className="text-center text-2xl font-bold" style={{ color: "#45425a" }}>No Posts. Create a new Post and give it it type</p>
                    )
                }

                <Divider className="m-2" />

                <div className='flex w-1/2 m-4' style={{ display: 'flex', width: '50%' }}>
                    {
                        data.allDatoCmsPost.distinct.map( (postType, index) => (
                            <ButtonBase
                                onClick={() => navigate('/admin/posts/select', {
                                    state: { postType }
                                })}
                                style={{
                                    width: '100%',
                                    margin: '1rem'
                                }}
                            >
                                <Paper 
                                    elevation={3}

                                    style={{
                                        padding: '1rem',
                                        paddingLeft: '1rem',
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p
                                            style={{ 
                                                fontFamily: 'monospace', 
                                                fontSize: 30,
                                                margin: 0,
                                                color: '#45425a'
                                            }}
                                        >
                                            {postType}
                                        </p>

                                        <Divider />

                                        <p 
                                            className="mt-4"
                                            style={{ 
                                                fontFamily: 'monospace', 
                                                fontSize: 20,
                                                alignSelf: 'center',
                                                color: '#45425a'
                                            }}>
                                            {data.allDatoCmsPost.group[index].totalCount} Posts
                                        </p>
                                    </div>
                                </Paper>
                            </ButtonBase>
                        ))
                    }
                </div>
            </div>
            <Tooltip 
                title={"Add Post Type"} 
                placement='left-start'
                className="hi"
                style={{
                    position: 'fixed',
                    bottom: 40,
                    right: 40,
                    backgroundColor: '#45425a',
                    width: 100,
                    height: 100,
                    WebkitBoxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)',
                    boxShadow: '1px 2px 14px 1px rgba(0,0,0,0.80)' 
                }}
            >
                <IconButton 
                    onClick={() => setDisplayOverlay(true) }
                >
                    <AddIcon style={{ textAlign: 'right', fontSize: 50, color: 'whitesmoke' }} />
                </IconButton>
            </Tooltip>
            {
                displayOverlay && (
                    <div
                        style={{
                            position: 'fixed',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            top: 0,
                            left: 0,
                            zIndex: 2
                        }}
                    >
                         <div 
                            style={{ 
                                backgroundColor: 'whitesmoke',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                alignContent: 'center',
                                width: '60%',
                                height: '40%',
                                alignItems: 'center',
                                borderRadius: 5,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderColor: 'grey',
                                position: 'relative',
                                zIndex: 3,
                                top: '50%',
                                margin: 'auto',
                                transform: 'translate(0, -50%)'
                            }}
                        >

                            <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                                <h2 style={{ fontFamily: 'monospace', textAlign: 'center' }}>Create New Post Type</h2>
                                <TextField 
                                    variant='filled' 
                                    value={newPostTitle}
                                    label="New Post Title"
                                    onChange={(e) => {
                                        setNewPostTitle(e.target.value);
                                    }} 
                                    style={{
                                        margin: 20
                                    }}
                                />
                                <TextField 
                                    varient="filled"
                                    value={newPostType}
                                    label="New Post Type (Plural Type Name) ex: stories"
                                    onChange={(e) => {
                                        setNewPostType(e.target.value);
                                    }}
                                    style={{
                                        margin: 20
                                    }}
                                />
                                <TextField 
                                    varient="filled"
                                    value={newSingularPostType}
                                    label="Singular Type Name - ex: story"
                                    onChange={(e) => {
                                        setNewSingularPostType(e.target.value);
                                    }}
                                    style={{
                                        margin: 20
                                    }}
                                />
                                <Tooltip title="Add Post Type" placement="right" arrow>
                                    <IconButton style={{ width: 'fit-content', margin: 'auto' }} 
                                        variant="contained" 
                                        aria-label="Update post" 
                                        onClick={() => createNewPostType() }
                                    >
                                        <AddIcon style={{ color: "#133c55", padding: 5, borderRadius: 40, margin: 10 }} fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>

                        <IconButton 
                            style={{ 
                                position: 'fixed', 
                                right: 20, 
                                top: 20, 
                                zIndex: 5
                            }} 
                            onClick={() => setDisplayOverlay(false) } 
                            title="close"
                        >
                            <CloseIcon style={{ 
                                color: 'whitesmoke'
                            }} />
                        </IconButton>
                    </div>
                )
            }
        </Layout>
    )
}

export const query = graphql`
    query getAllPosts($sitename: String) {
        allDatoCmsPost(filter: {site: {name: {eq: $sitename}}}) {
            distinct(field: posttype)
            group(field: posttype) {
                totalCount
            }
        }

        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`;

export default ChoosePost;